import { $host } from "./index";

export const fetchProductByCategory = async (categoryIds) => {
    try {
        const { data } = await $host.post('api/product/category',  categoryIds )
        return data;
    } catch (error) {
        console.error(error);
        throw new Error('Помилка отримання списку товарів');
      }
}

export const fetchOneProduct = async (id_1c) => {
    try {
        const {data} = await $host.get('api/product/' + id_1c)
        return data;
    } catch (error) {
        console.error(error);
        throw new Error('Помилка отримання товару');
      }
}