import React, { useState } from 'react';
import "../registration/registration.css";
import "./confirmationOrder.css"
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTE } from '../../utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from '../../components/Redux_toolkit/toolkitSlice';
import Novapost from '../../components/NovaPost';
import { createOrder } from '../../http/order';


function ConfirmationOrder() {
 
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [telef, setTelef] = useState('');
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const dispatch = useDispatch()
  const { basket, user} = useSelector(state => state.toolkit)
  const { RemoveBasket,RemoveBasketAll} = toolkitSlise.actions

  const navigate = useNavigate();
  const backToHome = () => {
    navigate(MAIN_ROUTE);
  };



  const formatTelef = (value) => {
    let digitsOnly = value.replace(/[^\d]/g, ''); // Видаляємо всі нечислові символи
    const maxLength = 12; // Максимальна довжина номера телефону

    let formattedTelef = '+38 ';

    if (digitsOnly.length > maxLength) {
      digitsOnly = digitsOnly.slice(0, maxLength);
    }

    if (digitsOnly.length >= 3) {
      formattedTelef += `(${digitsOnly.slice(2, 5)}) `;
    }

    if (digitsOnly.length >= 6) {
      formattedTelef += `${digitsOnly.slice(5, 8)}-`;
    }

    formattedTelef += digitsOnly.slice(8);

    return formattedTelef;
  }

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const formattedTelef = formatTelef(value);
    setTelef(formattedTelef);
  }
  


  function confirmation () {
    let obj = {
      firstName: firstName,
      secondName: secondName,
      telef: telef,
      city: selectedCity,
      warehouse: selectedWarehouse,
      basket: basket,
    }
    console.log(obj);
    
    if(telef.length < 18){
      alert("номер телефону введено не коректно")
    } else {
      createOrder(obj).then(data => {
        setShowModal(true);
        dispatch(RemoveBasketAll());
      })
      .catch(() => {
        alert("Виникла помилка при відправленні замовлення");
      });
    }
    
  }

  return (
    <div className="Registration">
      
      {basket.length > 0 ? (
                                <div> {basket.map((item) => 
                                    <div className='OrderContainer'>
                                        <div className='Order__itemCardImg'>
                                                <img src={item.image[0]} alt="foto" />
                                            </div>
                                            <p>{item.name}</p>
                                            <p id='item__price'>{Math.floor(item.priceUSD*38)} грн</p>
                                            <input type="button" value={'Delet'} onClick={() => dispatch(RemoveBasket(item.id_1c))}/>
                                    </div>
                                    )}
                                    <p id='OrderTotal'> Ваше замовлення на суму: <span>{Math.floor(basket.reduce((a,b) => a + b.priceUSD*38,0))}</span> грн</p> 
                                    </div>
                                    ) 
                                    :
                                    ( <div>Тут буде ваше замовлення</div>)}
      <div className='Registration__container'>
        {showModal && (
          <div className="modal">
            <h3>Вітаю!</h3>
            <p>Ваше замовлення відправлене в обробку</p>
            <p>очикуйте підтвердження</p>
            <button onClick={() => {
              setShowModal(false);
              backToHome();
            }}>Ok</button>
          </div>
        )}
        <div className='order'>

        </div>
        Ім'я <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" />
        Призвіще <input value={secondName} onChange={e => setSecondName(e.target.value)} type="text" />
        Номер телефону <input value={telef} onChange={handlePhoneChange} type="text" />
        {user.opt ?
          (<div className='delivery__metod'>
            <h3>Доставка здійснюеться Новою Поштою</h3>
            <p>Оберіть місто та відділення:</p>
            <Novapost 
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              selectedWarehouse={selectedWarehouse}
              setSelectedWarehouse={setSelectedWarehouse}
            />
          </div>)
          :
          (null)
          }
        <input className='Registration__button' type="submit" value="Підтвердити замовлення" onClick={confirmation} />
      </div>
    </div>
  );
}

export default ConfirmationOrder;
