import React, { useEffect, useState } from 'react';
import './adminPanel.css'
import './buttonios.css'
import ListItem from './ListItem.js'
import { fetchUser } from '../../http/userApi';


function AdminPanel() {
  
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const data = await fetchUser ();
    setUsers(data)
    
  }
  useEffect(() => {
    fetchUsers();
  }, []);
  
  console.log(users);

  let countOptUsers = 0
  let countRitailUsers = 0
  const countAllUsers = users.length + 1
  
  for (let i = 0; i < users.length; i++) {
    users[i].opt ? countOptUsers ++ : countRitailUsers ++; 
  }

    return (
            <div className='Table__content'>
                <div className='countUsers'>
                  <h3>Кількість користувачів - {countAllUsers}</h3>
                  <p>Оптові - {countOptUsers} </p>
                  <p>Роздрібні - {countRitailUsers}</p>
                </div>
                <table>
                    <thead>
                      <tr>
                          <th>Ім'я</th>
                          <th>Призвіще</th>
                          <th>Місто</th>
                          <th>Номер телефону</th>
                          <th>Пароль</th>
                          <th>Оптовий</th>
                          <th>Менеджер</th>
                      </tr>
                    </thead>
                    {users.map( users =>
                      <ListItem key={users._id} 
                        id={users._id}
                        firstName={users.firstName}
                        secondName={users.secondName}
                        city={users.city}
                        telef={users.telef}
                        password={users.password}
                        opt={users.opt}
                        manager={users.manager}
                        />
                    )}
                    
                  </table>
                </div>
)}

export default AdminPanel;