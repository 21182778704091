import ButtonBack from '../../components/ButtonBack';
import React, { useEffect, useState } from "react";
import './productCard.css'
import '../catalog/Catalog.css'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from "../../components/Redux_toolkit/toolkitSlice";
import { fetchOneProduct } from "../../http/productAPI";

function ProductCard() {
    

    const navigate = useNavigate()
    const {id_1c} = useParams()    
    const dispatch = useDispatch()
    const {AddFavorit, RemoveFavorit,AddBasket,RemoveBasket} = toolkitSlise.actions
    const {favorite, basket, user} = useSelector(state => state.toolkit)
    const [product, setProduct] = useState({})

        const fetchOne = async (id_1c) => {
        const data = await fetchOneProduct(id_1c);
        setProduct(data);
        }
    
      useEffect(() => {
        fetchOne(id_1c);
      }, []);
      
      const formatDescription = (description) => {
        if (!description) return '';
        const replacedNewlines = description.replace(/\r\n/g, '<br/>');
        const formattedDescription = replacedNewlines.replace(/(.*?):(.+?)(<br\/>|$)/g, '<strong class="highlight">$1:</strong>$2<br/>');
        return formattedDescription;
    };

    return (
       <div className='Card'>
            <div class="Contact__item-L"></div>
                <div className='Card__container'>
                    <ButtonBack />
                    <div className='Card__item'>
                        <div className='Card__foto'>
                            {product.image && product.image.length > 0 && (
                                <img src={product.image[0]} alt="item" />
                                )}
                        </div>
                        <div className='Card__description'>
                            <h2>{product.name}</h2>
                            <p> {user ? (product.priceUSD * 38).toFixed(2): ((product.priceUSD * 38)*1.3).toFixed(2)} грн</p>
                            <div className="Card__descriptionProd">
                                <p>Опис товару:</p>
                                {product.description && (
                                     <div dangerouslySetInnerHTML={{ __html: formatDescription(product.description) }}></div>
                                )}
                            </div>
                            {basket.find((elem) => elem.id_1c === product.id_1c) ? (
                                            <input className='ButtonActive' type="button" value={'Відмінити'} onClick={() => dispatch(RemoveBasket(product.id_1c))} />
                                        ) 
                                        : 
                                        (
                                            <input type="button" value={'В корзину'} onClick={() => dispatch(AddBasket(product))} />
                                        )}
                    
                    {favorite.find((elem) => elem.id_1c === product.id_1c) ? (
                                            <input className='ButtonActive' type="button" value={'Обране'} onClick={() => dispatch(RemoveFavorit(product.id_1c))} />
                                        ) 
                                        : 
                                        (
                                            <input type="button" value={'До обраного'} onClick={() => dispatch(AddFavorit(product))} />
                                        )}
                            
                        </div>
                    </div>
                </div>               
            <div class="Contact__item-L"></div>
           
        </div>
    );
}

export default ProductCard;
