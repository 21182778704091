import React from 'react';
import "./Contact.css"
import map from "../../img/mapU.png"

function Contacts() {

    return (
        <div className="catalog">
            <div className='catalog__conteiner'>
                <div className='contacts__container'>
                    <img className='contacts__img' src={map} alt="map"/>
                    <div className='contacts__sell'>

                    </div>
                    <div className='contacts__map'>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
