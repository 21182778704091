import { $host } from "./index";



export const createUser = async (post) => {
    
    try {
        const {data} = await $host.post('api/user', post)
    return data;
    } catch (error) {
        console.error(error);
        throw new Error('Помилка створення користувача');
      }
}
export const loginUser = async (post) => {
    
    try {
        const {data} = await $host.post('api/login', post)
        return data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            throw new Error('Невірний пароль');
          } else if (error.response && error.response.status === 404) {
            throw new Error('Користувача не знайдено');
          }
      }
}

export const fetchUser = async () => {
    try {
        const {data} = await $host.get('api/user')
        return data;
    } catch (error) {
        console.error(error);
        throw new Error('Помилка отримання списку користувачів');
      }
}
export const fetchOneUser = async (id) => {
    const {data} = await $host.get('api/user/' + id)
    return data;
}
export const putOneUser = async (post) => {
    const {data} = await $host.put('api/user/', post)
    return data;
}
