
import Catalog from "../pages/catalog/Catalog"
import MainPage from "../pages/mainpage/Mainpage"
import ProductCard from "../pages/productCard/productCard"
import Registration from "../pages/registration/registration"
import {CARD_ROUTE, CATALOG_ROUTE, CATEGORY_ROUTE, CONDITION_ROUTE, CONFIRMATION_ROUTE, CONTACTS_ROUTE, DELIVERY_ROUTE, MAIN_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE } from "../utils/consts"
import ConfirmationOrder from "../pages/confirmationOrder/confirmationOrder"
import Category from "../pages/category/Category"
import Products from "../pages/products/Products"
import Contacts from "../pages/contacts/Contacts";

export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        Component:  <MainPage />
    },
    {
        path: CATALOG_ROUTE ,
        Component:  <Catalog />
    },
    {
        path: REGISTRATION_ROUTE,
        Component:  <Registration />
    },
    {
        path: CARD_ROUTE + '/:id_1c',
        Component:  <ProductCard />
    },
    {
        path: CATALOG_ROUTE + CATEGORY_ROUTE + '/:id',
        Component:  <Category />
    },
    {
        path: CONTACTS_ROUTE,
        Component:  <Contacts />
    },
   
    // {
    //     path: CONTACTS_ROUTE,
    //     Component: <ContactPage />
    // }
]

export const privateRoutes = [
    {
        path: MAIN_ROUTE,
        Component:  <MainPage />
    },
    {
        path: CATALOG_ROUTE ,
        Component:  <Catalog />
    },
    {
        path: CARD_ROUTE + '/:id_1c',
        Component:  <ProductCard/>
    },
    {
        path: CONFIRMATION_ROUTE,
        Component: <ConfirmationOrder />
    },
    {
        path: CATALOG_ROUTE + CATEGORY_ROUTE + '/:id',
        Component:  <Category />
    },
    {
        path: CONTACTS_ROUTE,
        Component:  <Contacts />
    },
    
    
]