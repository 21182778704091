import HeaderWithButtonBack from '../../components/HeaderWithButtonBack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from '../../components/Redux_toolkit/toolkitSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { CARD_ROUTE } from '../../utils/consts';
import { fetchProductByCategory } from '../../http/productAPI';
import LeftMenu from '../catalog/LeftMenu';

function Products() {
    
    const dispatch = useDispatch()
    const {AddFavorit, RemoveFavorit,AddBasket,RemoveBasket} = toolkitSlise.actions
    const {favorite, basket, user} = useSelector(state => state.toolkit)
    const navigate = useNavigate()
    const [product, setProduct] = useState([])
    const {category_id} = useParams()    

    let categoryId

    useEffect(() => {
        if(category_id === 'deep'){
            categoryId = [ 111, 110 ]
        } else if(category_id === 'poverh'){
            categoryId = [ 114 ]
        } else if(category_id === 'fecal'){
            categoryId = [ 112 ]
        } else if(category_id === 'cirkul'){
            categoryId = [ 113 ]
        } else if(category_id === 'vibro'){
            categoryId = [ 109 ]
        } else if(category_id === 'components'){
            categoryId = [ 105 ]
        } else if(category_id === 'pumpStations'){
            categoryId = [ 115 ]
        } else if(category_id === 'parts'){
            categoryId = [ 107 ]
        } else if(category_id === 'tanks'){
            categoryId = [ 106 ]
        }
        else {
            categoryId = [];
        }

        fetchProduct(categoryId);
      }, [category_id]);

    const fetchProduct = async () => {
        const data = await fetchProductByCategory(categoryId);
        setProduct(data);
      }
    
    if (!product.length) return [];

    return ( 
        <div className="catalog">
        <div className='catalog__conteiner'>
        <LeftMenu />
        <div className='catalog__rightbar'>

            <HeaderWithButtonBack headrText={'Насоси'}/>

            <div className='rightbar__sort'>
                <span id='categoryFiltr'><input type="button" value='Катеорії та Фільтр' /></span>
                <span>Сортувати:</span> <span className='sort__item'>спочатку дорогі</span> 
                <span className='sort__item'>спочатку дешеві</span>
            </div>
            <div className='rightbar__products'>
                {product.map((item) => 
                        <div className='rightbar__productsItem'  key={item.name} >
                            <div className='rightbar__productsImg' onClick={() => navigate(CARD_ROUTE + '/' + item.id_1c)}>
                                <img src={item.image[0]} alt="item" /></div>
                            <div className='rightbar__productsName' onClick={() => navigate(CARD_ROUTE + '/' + item.id_1c)}>{item.name}</div>
                            <div className='rightbar__productsPrice'>{user ? (item.priceUSD * 38).toFixed(2): ((item.priceUSD * 38)*1.3).toFixed(2)} грн</div>
                            <div className='rightbar__productsButton'>
                                {basket.find((elem) => elem.id_1c === item.id_1c) ? (
                                        <input className='buttonActive' type="button" value={'Відмінити'} onClick={() => dispatch(RemoveBasket(item.id_1c))} />
                                    ) 
                                    : 
                                    (
                                        <input type="button" value={'В корзину'} onClick={() => dispatch(AddBasket(item))} />
                                    )}
                                {favorite.find((elem) => elem.id_1c === item.id_1c) ? (
                                        <input className='buttonActive' type="button" value={'Обране'} onClick={() => dispatch(RemoveFavorit(item.id_1c))} />
                                    ) 
                                    : 
                                    (
                                        <input type="button" value={'До обраного'} onClick={() => dispatch(AddFavorit(item))} />
                                    )}
                            </div>
                </div>)}
        </div>
    </div>
    </div>
    </div>
  );
}

export default Products;
