import React, { useEffect, useMemo, useState } from 'react';
import pump from '../../img/pump.png'
import steelFiting from '../../img/steelFiting.png'
import pprFiting from '../../img/pprFiting.png'
import smesitel from '../../img/smesitel.png'
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from '../../components/Redux_toolkit/toolkitSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { CARD_ROUTE, CATALOG_ROUTE, CATEGORY_ROUTE } from '../../utils/consts';
import { fetchProduct } from '../../http/productAPI';
import { categoriesPump } from '../../store/categories';
import LeftMenu from '../catalog/LeftMenu';

function PprFiting() {
    
    const dispatch = useDispatch()
    const {AddFavorit, RemoveFavorit,AddBasket,RemoveBasket} = toolkitSlise.actions
    const {favorite, basket, user} = useSelector(state => state.toolkit)
    const navigate = useNavigate()
    const [product, setProduct] = useState([])
    const [selectedFilters, setSelectedFilters] = useState([]);

    const {id} = useParams()   
    console.log(id);


    const arr = [
       
        {id: 'pprFiting', image: pprFiting, name: 'PPR фітинг'},
   
    ]


    return (
        
            <div className='catalog__rightbar'>
                <h1 className='catalog__rightbarH1'>Категорії товарів</h1>
                    <div className='productsCategories__conteiner'>        
                            {arr.map((item) => 
                                <div className='Categories__item'  key={item.name} onClick={null}>
                                    <div className='Categories__itemImg'>
                                        <img src={item.image} alt="" /></div>
                                    <div className='Categories__itemName'>{item.name}</div>
                            </div>)}
                    </div>
            </div>
  );
}

export default PprFiting;
