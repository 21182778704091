import {categoryPumps} from '../../utils/constantsCategory';
import {CATALOG_ROUTE} from "../../utils/consts";
import React from "react";
import deep from "../../img/categoriesPump/deep.png";
import poverh from "../../img/categoriesPump/poverh.png";
import fecal from "../../img/categoriesPump/fecal.png";
import cirkul from "../../img/categoriesPump/cirkul.png";
import pprFiting from "../../img/pprFiting.png";
import steelFiting from "../../img/steelFiting.png";
import smesitel from "../../img/smesitel.png";
import umivalnik from "../../img/categorySmes/umivalnik.png";
import moyka from "../../img/categorySmes/moyka.png";
import {useNavigate} from "react-router-dom";


function SubMenu({menuItem, subMenuOpen, setSubMenuOpen}) {
    const navigate = useNavigate()


    const subCategory = [
        {pump: [
                ...categoryPumps,
            ]},
        // {pprFiting: [
        //         {id: 'pprFiting', image: pprFiting, name: 'PPR фітинг'},
        //     ]},
        // {steelFiting: [
        //         {id: 'steelFiting', image: steelFiting, name: 'Стальний фітинг'},
        //     ]},
        // {smesitel: [
        //         {id: 'smesitel', image: smesitel, name: 'Ванна'},
        //         {id: 'smesitel', image: umivalnik, name: 'Умивальник'},
        //         {id: 'smesitel', image: moyka, name: 'Мийка'},
        //     ]},

    ]

    function getSubCategoryByKey(key) {
        // Шукати об'єкт у масиві subCategory, який містить переданий ключ
        const foundItem = subCategory.find(item => Object.keys(item)[0] === key);

        // Повернути підмасив, якщо знайдено, або порожній масив, якщо не знайдено
        return foundItem ? Object.values(foundItem)[0] : [];
    }
    const result = getSubCategoryByKey(menuItem);

    return (
        <div className={subMenuOpen ? 'catalog__subCategory' : 'catalog__subCategoryNone'}
             onMouseEnter={() => setSubMenuOpen(true)}
             onMouseLeave={() => setSubMenuOpen(false)}>
            <div className='subCategory__conteiner'>
                <h3>Категорії</h3>
                {result?.map((item) =>
                    <div className='leftbar__item'
                         key={item.name}
                         onClick={() =>
                             navigate(CATALOG_ROUTE + '/' + menuItem + '/' + item.id)}
                    >
                        <div className='leftbar__itemImg'>
                            <img src={item.image} alt="pumps"/>
                        </div>
                        <div className='leftbar__itemName'>{item.name}</div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default SubMenu;