import React, { useState } from 'react';
import "./Catalog.css"
import pump from '../../img/pump.png'
import steelFiting from '../../img/steelFiting.png'
import pprFiting from '../../img/pprFiting.png'
import smesitel from '../../img/smesitel.png'
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from '../../components/Redux_toolkit/toolkitSlice';
import { useNavigate } from 'react-router-dom';
import { CATALOG_ROUTE } from '../../utils/consts';
import LeftMenu from './LeftMenu';

function Catalog() {
    
    const dispatch = useDispatch()
    const {AddFavorit, RemoveFavorit,AddBasket,RemoveBasket} = toolkitSlise.actions
    const {favorite, basket, user} = useSelector(state => state.toolkit)
    const navigate = useNavigate()
    const [product, setProduct] = useState([])
    const [selectedFilters, setSelectedFilters] = useState([]);

    const arr = [
        {id: 'pump', image: pump, name: 'Насоси'},
        {id: 'pprFiting', image: pprFiting, name: 'PPR фітинг'},
        {id: 'steelFiting', image: steelFiting, name: 'Стальний фітинг'},
        {id: 'smesitel', image: smesitel, name: 'Змішувачі'},    
    ]

     
    return (
    <div className="catalog">
        <div className='catalog__conteiner'>
           <LeftMenu />
            <div className='catalog__rightbar'>
                <h1 className='catalog__rightbarH1'>Категорії товарів</h1>
                <div className='productsCategories__conteiner'>
                    {arr.map((item) =>
                        <div className='Categories__item' key={item.name}
                             onClick={() => navigate(CATALOG_ROUTE + '/' + item.id)}>
                            <div className='Categories__itemImg'>
                                <img src={item.image} alt=""/></div>
                            <div className='Categories__itemName'>{item.name}</div>
                        </div>)}
                </div>
            </div>
        </div>
    </div>
    );
}

export default Catalog;
