import {categoryPumps} from '../../utils/constantsCategory';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from '../../components/Redux_toolkit/toolkitSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { CATALOG_ROUTE } from '../../utils/consts';


function Pumps() {
    
    const dispatch = useDispatch()
    const {AddFavorit, RemoveFavorit,AddBasket,RemoveBasket} = toolkitSlise.actions
    const {favorite, basket, user} = useSelector(state => state.toolkit)
    const navigate = useNavigate()
    const [product, setProduct] = useState([])
    const [selectedFilters, setSelectedFilters] = useState([]);

    const {id} = useParams()   



    return (

        <div className='catalog__rightbar'>
            <div style={{display: 'flex', alignItems: 'end'}}>
                <button onClick={() => navigate(CATALOG_ROUTE)}
                        style={{
                            border: '1px solid rgb(6, 152, 255)',
                            padding: '5px',
                            marginLeft: '5px'
                        }}>Назад до каталогу
                </button>
                <h1 className='catalog__rightbarH1'>Категорії</h1>
            </div>
            <div className='productsCategories__conteiner'>
                {categoryPumps.map((item) =>
                    <div className='Categories__item' key={item.name} onClick={() =>
                        navigate(CATALOG_ROUTE + '/' + id + '/' + item.id)}>
                        <div className='Categories__itemImg'>
                            <img src={item.image} alt=""/></div>
                        <div className='Categories__itemName'>{item.name}</div>
                    </div>)}
            </div>
        </div>
    );
}

export default Pumps;
