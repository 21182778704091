import React, { useState } from 'react';
import "./Navbar.css"
import usericon from '../img/user.png'
import hart from '../img/hart.png'
import bascet from '../img/bascet.png'
import logo from '../img/logo.png'
import BurgerMenuImg from '../img/BurgerMenu.png'
import {
    ADMIN_ROUTE,
    CATALOG_ROUTE,
    CONFIRMATION_ROUTE,
    CONTACTS_ROUTE,
    MAIN_ROUTE,
    REGISTRATION_ROUTE
} from '../utils/consts';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from './Redux_toolkit/toolkitSlice';
import { useAuthState } from 'react-firebase-hooks/auth';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { loginUser } from '../http/userApi';

function Navbar() {
  
  const navigate = useNavigate();
  
  const [menuActive, setMenuActive] = useState (false)
  const [userActive, setUserActive] = useState (false)
  const [favoritActive, setfavoritActive] = useState (false)
  const [bascetActive, setbascetActive] = useState (false)
  const dispatch = useDispatch()
  const {favorite, basket, user} = useSelector(state => state.toolkit)
  const { RemoveFavorit, RemoveBasket, AddBasket, addUser,signOut} = toolkitSlise.actions
  // const {userAuth} = useSelector(state => state.toolkit)
  // const [userGoogl] = useAuthState(userAuth)
  const [telef, setTelef] = useState('');
  const [password, setPassword] = useState('');
  



// const Login = async () => {
//     const provider = new GoogleAuthProvider();
//     const {user} = await signInWithPopup(userAuth, provider)
// }
  
const formatTelef = (value) => {
  let digitsOnly = value.replace(/[^\d]/g, ''); // Видаляємо всі нечислові символи
  const maxLength = 12; // Максимальна довжина номера телефону

  let formattedTelef = '+38 ';

  if (digitsOnly.length > maxLength) {
    digitsOnly = digitsOnly.slice(0, maxLength);
  }

  if (digitsOnly.length >= 3) {
    formattedTelef += `(${digitsOnly.slice(2, 5)}) `;
  }

  if (digitsOnly.length >= 6) {
    formattedTelef += `${digitsOnly.slice(5, 8)}-`;
  }

  formattedTelef += digitsOnly.slice(8);

  return formattedTelef;
}

  const handleMenuOpen = () => {
      setMenuActive(!menuActive)
  }

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const formattedTelef = formatTelef(value);
    setTelef(formattedTelef);
  }

  const signIn = () => {
    
    const post = {
      telef: telef,
      password: password
    }

    if (telef.length < 18) {
      alert('Номер телефону введено не коректно')
    } else {
      loginUser(post)
      .then(data => dispatch(addUser(data)))
      .catch(error => {
        console.error(error);
        if (error.message === 'Невірний пароль') {
          alert('Невірний пароль');
        } else if (error.message === 'Користувача не знайдено') {
          alert('Користувача не знайдено');
        } else {
          alert('Помилка входу');
        }
      });
    }
  }

    function confirmationOrder(){
      if (!user){
        alert('Для підтвердження замовлення вам потрібно авторизуватись')
      } else {
        navigate(CONFIRMATION_ROUTE)
      }
    }

    const handleCloseBurgerMenu = (e) => {
        if (e.target.classList.contains('header__burgerMenuActive')) {
            setMenuActive(false);
        }
    };

    const handleNavigateMain = () => {
        navigate(MAIN_ROUTE)
        setMenuActive(false);
    };
    const handleNavigateCatalog = () => {
        navigate(CATALOG_ROUTE)
        setMenuActive(false);
    };
    const handleNavigateContacts = () => {
        navigate(CONTACTS_ROUTE)
        setMenuActive(false);
    };


    return (
    <div className="header">
        <div className="header__container">
          <div className='header__burgerMenu'>
            <input
                className="header__burger"
                type="image"
                onClick={handleMenuOpen}
                src={BurgerMenuImg}
                alt="Кнопка «menu»">
            </input>
          </div>
          <div className={menuActive ? "header__burgerMenuActive"  : "header__burgerMenuDisactive" } onClick={handleCloseBurgerMenu}>
              <div className="burgerMenuActive__container" >
                  <span id='burgerMenuActive__closeButton' onClick={() => setMenuActive(false)}>X</span>
                  <p onClick={handleNavigateMain}>Головна</p>
                  <p onClick={handleNavigateCatalog} >Категорії</p>
                  <p onClick={handleNavigateContacts}>Контакти</p>
              </div>
          </div>
          <div className='header__logo' onClick={() => navigate(MAIN_ROUTE)}>
            <img src={logo} alt="logo" />
          </div>
          <div className='hedaer__content'>
            <div className='hedaer__navButton'>
              <p onClick={() => navigate(MAIN_ROUTE)}>Головна</p>
              <p onClick={() => navigate(CATALOG_ROUTE)}>Каталог</p>
              <p onClick={() => navigate(CONTACTS_ROUTE)}>Контакти</p>
            </div>
            
            <div className='hedaer__icons'>
              <div onClick={() => {
                                    setUserActive(!userActive);
                                    setfavoritActive(false)
                                    setbascetActive(false)
                                     }}> <img src={usericon} alt="user"/></div>
              <div onClick={() => {
                                    setfavoritActive(!favoritActive)
                                    setUserActive(false)
                                    setbascetActive(false)
                                    }}> <img src={hart} alt="hart" /></div>
              <div onClick={() => {
                                    setbascetActive(!bascetActive)
                                    setUserActive(false)
                                    setfavoritActive(false)
                                    }}> <img src={bascet} alt="bascet" /></div>
            </div>
            <div className={userActive ? 'userMenu active' : 'userMenu'} onClick={() => {
                    setfavoritActive(false);
                    setbascetActive(false);
                    setUserActive(false)
                    }}>  
                    <div className='userMenu__content' onClick={e => e.stopPropagation()}>
                      {user ? 
                        (<div className='User__authorisationTrue'>
                          <p>Добрий день! {user.firstName} {user.secondName}</p>
                          {user.opt ? (
                              user.manager ? (
                                <div>
                                <p>Ваш персональний менеджер {user.manager.name}</p>
                                <p>Телефон менеджера:{user.manager.tel}</p>
                                </div>
                                )
                                :(null) 
                              )
                            :(null)}
                          {user.admin ? (
                            <input className='input__numberBut' type="button" value="Адмін панель" onClick={() => navigate(ADMIN_ROUTE)}/>
                            )
                            :(null)}
                          <input className='input__numberBut' type="button" value="Вийти" onClick={() => dispatch(signOut(false))}/>
                        </div>) 
                        :
                        (
                          <div className='User__authorisation'>
                            <p>Увійти за номером телефону</p>
                            <input className='input__number' value={telef} onChange={handlePhoneChange} type="text" placeholder='Номер телефону'/>
                            <input className='input__number' value={password} onChange={e => setPassword(e.target.value)} type="text" placeholder='Пароль'/>
                            <div>
                            <input className='input__numberBut' type="button" value="Увійти" onClick={signIn}/>
                            <input className='input__numberBut' type="button" value="Реєстрація" onClick={() => navigate(REGISTRATION_ROUTE)} />
                            </div>
                            {/* <p>Або</p> */}
                            {/* <input className='input__numberBut' type="button" value="Увійти за допомогую Google" onClick={Login} /> */}
                        </div>
                        )}
                    </div>
              </div>
            <div className={favoritActive ? 'userMenu active' : 'userMenu'} onClick={() => {
                    setfavoritActive(false);
                    setbascetActive(false);
                    }}>  
                    <div className='userMenu__content' onClick={e => e.stopPropagation()}>
                    {favorite.length > 0 ? (
                    favorite.map((item) => 
                        <div className='Shop__favoritePage'>
                            <div className='Shop__itemCardImg'>
                                    <img src={item.image} alt="foto" />
                                </div>
                                <p>{item.name}</p>
                                <p id='item__price'>{user ? (item.priceUSD * 38).toFixed(2): ((item.priceUSD * 38)*1.3).toFixed(2)} грн</p>
                                <div className='Shop__favoritePageButton'>
                                  <input type="button" value={'В корзину'} onClick={() => dispatch(AddBasket(item))}/>
                                  <input type="button" value={'Delet'} onClick={() => dispatch(RemoveFavorit(item.id_1c))}/>
                                </div>
                        </div>
                        )) 
                        :
                        ( <div>Тут буде ваше обаране</div>)
                    }
                    </div>
              </div> 

                    <div className={bascetActive ? 'userMenu active' : 'userMenu'} onClick={() => { setbascetActive(false) }}>    
                      <div className='userMenu__content' onClick={e => e.stopPropagation()}>
                    
                           <div className='UserMenu__notAuth'>
                           {basket.length > 0 ? (
                                <div> {basket.map((item) => 
                                    <div className='Shop__favoritePage'>
                                        <div className='Shop__itemCardImg'>
                                            <img src={item.image[0]} alt="foto" />
                                        </div>
                                        <p className='Shop__itemName'>{item.name}</p>
                                        <p id='item__price'>{user ? (item.priceUSD * 38).toFixed(2) : ((item.priceUSD * 38)*1.3).toFixed(2)} грн</p>
                                        <input type="button" value={'Delet'} onClick={() => dispatch(RemoveBasket(item.id_1c))}/>
                                    </div>
                                    )}
                                    <p id='orderPrice'> Ваше замовлення на суму: {basket.reduce((a,b) => a + b.priceUSD*38,0).toFixed(2)} грн</p>
                                    <input id='orderButton' type="button" value={'Підтвердити замовлення'} onClick={confirmationOrder} /> 
                                    </div>
                                    ) 
                                    :
                                    ( <div>Тут буде ваше замовлення</div>)}
                               
                            </div>    
                       
                    </div>
                </div>
                
          </div>
        </div>
    </div>
  );
}

export default Navbar;
