import React, { useState } from 'react';
import "./Catalog.css"
import pump from '../../img/pump.png'
import steelFiting from '../../img/steelFiting.png'
import pprFiting from '../../img/pprFiting.png'
import smesitel from '../../img/smesitel.png'
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from '../../components/Redux_toolkit/toolkitSlice';
import { useNavigate } from 'react-router-dom';
import SubMenu from "./SubMenu";

function LeftMenu() {
    
    const dispatch = useDispatch()
    const {AddFavorit, RemoveFavorit,AddBasket,RemoveBasket} = toolkitSlise.actions
    const {favorite, basket, user} = useSelector(state => state.toolkit)
    const navigate = useNavigate()
    const [product, setProduct] = useState([])
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [itemForSubmenu, setItemForSubmenu] = useState();

    const category = [
        {id: 'pump', image: pump, name: 'Насоси'},
        {id: 'pprFiting', image: pprFiting, name: 'PPR фітинг'},
        {id: 'steelFiting', image: steelFiting, name: 'Стальний фітинг'},
        {id: 'smesitel', image: smesitel, name: 'Змішувачі'},    
    ]

    const handleOpenSubMenu = (key) => {

        setItemForSubmenu(key)
        setSubMenuOpen(true)
    }


    return (
        <div className='catalog__leftbar'>
            <div className='leftbar__categories'>
                <h2>Каталог товарів</h2>
                {category.map((item) =>
                    <>
                        <div className='leftbar__item'
                             key={item.name}
                             onMouseEnter={() => handleOpenSubMenu(item.id)}
                             onMouseLeave={() => setSubMenuOpen(false)}
                        >

                            <div className='leftbar__itemImg'>
                                <img src={item.image} alt="pumps"/>
                            </div>
                            <div className='leftbar__itemName'>{item.name}</div>
                        </div>
                        <SubMenu
                            subMenuOpen={subMenuOpen}
                            menuItem={itemForSubmenu}
                            setSubMenuOpen={setSubMenuOpen}
                        />
                    </>
                    )}
            </div>

        </div>
    );
}

export default LeftMenu;
