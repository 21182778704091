import { $host } from "./index";



export const createOrder = async (post) => {
    
    try {
        const {data} = await $host.post('api/orders', post)
    return data;
    } catch (error) {
        console.error(error);
        throw new Error('Помилка відправлення замовлення');
      }
}

export const fetchOrders = async () => {
    try {
        const {data} = await $host.get('api/orders')
        return data;
    } catch (error) {
        console.error(error);
        throw new Error('Помилка отримання списку замовлень');
      }
}
export const fetchOneUser = async (id) => {
    const {data} = await $host.get('api/user/' + id)
    return data;
}
