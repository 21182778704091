import React, { useEffect, useMemo, useState } from 'react';
import pump from '../../img/pump.png'
import steelFiting from '../../img/steelFiting.png'
import pprFiting from '../../img/pprFiting.png'
import smesitel from '../../img/smesitel.png'
import { useDispatch, useSelector } from 'react-redux';
import { toolkitSlise } from '../../components/Redux_toolkit/toolkitSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { CARD_ROUTE, CATALOG_ROUTE, CATEGORY_ROUTE } from '../../utils/consts';
import { fetchProduct } from '../../http/productAPI';
import { categoriesPump } from '../../store/categories';
import LeftMenu from '../catalog/LeftMenu';
import Pumps from './Pumps';
import PprFiting from './PprFiting';
import Steel from './Steel';
import Smesitel from './Smesitel';

function Category() {
    
    const dispatch = useDispatch()
    const {AddFavorit, RemoveFavorit,AddBasket,RemoveBasket} = toolkitSlise.actions
    const {favorite, basket, user} = useSelector(state => state.toolkit)
    const navigate = useNavigate()
    const [product, setProduct] = useState([])
    const [selectedFilters, setSelectedFilters] = useState([]);

    const {id} = useParams()   


    return (
        <div className="catalog">
        <div className='catalog__conteiner'>
           <LeftMenu />
            {id === 'pump' && <Pumps />}
            {id === 'pprFiting' && <PprFiting />}
            {id === 'steelFiting' && <Steel />}
            {id === 'smesitel' && <Smesitel />}
        </div>
    </div> 
  );
}

export default Category;
