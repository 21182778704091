import {Routes, Route} from 'react-router-dom';
import { publicRoutes, privateRoutes } from './routes';
import MainPage from '../pages/mainpage/Mainpage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector } from 'react-redux';
import { ADMIN_ROUTE, CATALOG_ROUTE, CATEGORY_ROUTE } from '../utils/consts';
import AdminPanel from '../pages/adminPanel/adminPanel';
import Category from '../pages/category/Category';
import Products from '../pages/products/Products';

function AppRouter() {

  // const {userAuth} = useSelector(state => state.toolkit)
  // const [user] = useAuthState(userAuth)
  const { user } = useSelector(state => state.toolkit)
  const userAdmin = user.admin

  return (

    user ? 
    (
      <Routes>
        {privateRoutes.map(({path, Component}) =>
        <Route key={path} path={path} element={Component} />
        )}
         
        {userAdmin ? (
          <Route path={ADMIN_ROUTE} element={<AdminPanel />}/>
        )
        :
        (null)}

        <Route path={CATALOG_ROUTE + '/:id'} element={<Category />}/>
        <Route path={CATALOG_ROUTE + '/:id' + '/:category_id'} element={<Products />}/>
        <Route path="*" element={<MainPage />}/> 

      </Routes>
    )
    :
    (
      <Routes>
        {publicRoutes.map(({path, Component}) =>
        <Route key={path} path={path} element={Component} />
        )}

        <Route path={CATALOG_ROUTE + '/:id'} element={<Category />}/>
        <Route path={CATALOG_ROUTE + '/:id' + '/:category_id'} element={<Products />}/>
        <Route path="*" element={<MainPage />}/>  

        
      </Routes>
    )
    
  );
}

export default AppRouter;
