import cirkul from '../img/categoriesPump/cirkul.png';
import deep from '../img/categoriesPump/deep.png';
import fecal from '../img/categoriesPump/fecal.png';
import vibroPump from '../img/categoriesPump/OVM-60.png';
import poverh from '../img/categoriesPump/poverh.png';
import parts from '../img/categoriesPump/zapchasti.png';
import tank24l from '../img/categoriesPump/tank24l.png';
import automatick from '../img/categoriesPump/automaticTelev.png';
import pumpStation from '../img/categoriesPump/pumpStations.png';

export const categoryPumps = [
    {id: 'deep', image: deep, name: 'Глибинні'},
    {id: 'poverh', image: poverh, name: 'Поверхневі'},
    {id: 'fecal', image: fecal, name: 'Дренажні'},
    {id: 'cirkul', image: cirkul, name: 'Циркуляційні'},
    {id: 'vibro', image: vibroPump, name: 'Вібраційні'},
    {id: 'pumpStations', image: pumpStation, name: 'Насосні станції'},
    {id: 'components', image: automatick, name: 'Комплектуючі'},
    {id: 'tanks', image: tank24l, name: 'Баки та гідроакумулятори'},
    {id: 'parts', image: parts, name: 'Запчастини'},
]