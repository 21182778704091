import ButtonBack from '../components/ButtonBack';


const HeaderWithButtonBack = ({headrText}) => {


    return(
        <div style={{display: 'flex', alignItems: 'end'}}>
            <ButtonBack />
            <h1>{headrText}</h1>
            <div></div>
        </div>
    )
}

export default HeaderWithButtonBack