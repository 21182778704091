import { createSlice } from "@reduxjs/toolkit";
import 'firebase/firestore'
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {useAuthState} from 'react-firebase-hooks/auth' 

const app = initializeApp({
    apiKey: "AIzaSyB1Va3di07ZwWDXMApnoxeD0y7RhmOhQYM",
    authDomain: "lukon-da46a.firebaseapp.com",
    projectId: "lukon-da46a",
    storageBucket: "lukon-da46a.appspot.com",
    messagingSenderId: "315501194090",
    appId: "1:315501194090:web:045996110662e6c2241313",
    measurementId: "G-ESMSXVWS4J"
});

    // const auth = getAuth(app);
    // const [user] = useAuthState()


export const toolkitSlise = createSlice({
    name: 'toolkitUser',      // название слайса
    initialState: {       //    дефолтное состояние
        user: false,
        // userAuth: auth,
        favorite: [],
        basket: []
    },  
    reducers: {             // actions
        AddFavorit(state, action){
            state.favorite.push(action.payload)
        },
        RemoveFavorit(state, action){
            state.favorite = state.favorite.filter(data => data.id_1c !== action.payload)
        },
        AddBasket(state, action){
            state.basket.push(action.payload)
        },
        RemoveBasket(state, action){
            state.basket = state.basket.filter(data => data.id_1c !== action.payload)
        },
        RemoveBasketAll(state, action){
            state.basket = []
        },
        addUser(state, action){
            state.user = action.payload;
        },
        signOut(state, action){
            state.user = action.payload;
        },
    }               
})

export default toolkitSlise.reducer
export const {AddFavorit, RemoveFavorit,AddBasket,RemoveBasket, addUser, signOut} = toolkitSlise.actions