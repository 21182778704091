import React, { useEffect } from 'react';

import { NpCitySelect, NpWarehouseSelect, } from 'np-select';


function Novapost({ selectedCity, setSelectedCity, selectedWarehouse, setSelectedWarehouse }) {
    useEffect(() => {
        const API_KEY = '6a8761e8a409ff4bf3d87b2cb9b301d7';
    
        const warehouseSelect = NpWarehouseSelect({
            apiKey: API_KEY,
            input: {
              name: 'warehouse',
              placeholder: 'Оберіть відділення',
            },
            button: {
              text: 'Оберіть відділення',
            },
            root: document.querySelector('#warehouse'),
            onMounted: select => select.setDisabled(true),
            onSelect: (item) => {
              setSelectedWarehouse(item.value); // Збережемо обране відділення у стані компоненту ConfirmationOrder
            },
          });
      
          NpCitySelect({
            apiKey: API_KEY,
            input: {
              name: 'city',
              placeholder: 'Оберіть місто',
            },
            button: {
              text: 'Оберіть місто',
            },
            root: document.querySelector('#city'),
            onSelect: async (item, select) => {
              const warehouses = await select.api.getNpWarehouses(item.value);
      
              setSelectedCity(item.value); // Збережемо обране місто у стані компоненту ConfirmationOrder
              setSelectedWarehouse(null); // Обнулимо обране відділення, оскільки місто було змінене
      
              warehouseSelect.setOptions(warehouses);
              warehouseSelect.setDisabled(false);
              warehouseSelect.setOpen(true);
            },
          });
        }, [setSelectedCity, setSelectedWarehouse]);
      return (
        <div className='NovaPost'>
            <div className="select-wrapper">
                <div id="city"></div>
            </div>
            <div id="warehouse"></div>
      </div>
      );
    }
  export default Novapost;