import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putOneUser } from '../../http/userApi';

function ListItem({ id, firstName, secondName, city, telef, password, opt, manager }) {
  const [optChanged, setOptChanged] = useState(opt);
  const [selectedOption, setSelectedOption] = useState(''); // Значення за замовчуванням
  const [phoneNumber, setPhoneNumber] = useState(null); // Значення за замовчуванням

  // Отримання даних з сервера під час першого рендерингу компонента
  useEffect(() => {
    setSelectedOption(manager ? manager.val : '');
    setPhoneNumber(manager ? manager.tel : null);
  }, [manager]);

  // Оновлення значень selectedOption та phoneNumber при зміні optChanged
  useEffect(() => {
    if (!optChanged) {
      setSelectedOption('');
      setPhoneNumber(null);
    }
  }, [optChanged]);

  const handleOptionChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    if (newValue === '1') {
      setPhoneNumber({ name: "Марина", tel: "+38(096)111-11-11", val: 1 });
    } else if (newValue === '2') {
      setPhoneNumber({ name: "Микола", tel: "+38(096)222-22-22",val: 2});
    } else if (newValue === '3') {
      setPhoneNumber({ name: "Роман", tel: "+38(096)333-33-33",val: 3 });
    } else {
      setPhoneNumber(null);
    }
  };

  const handleCheckboxChange = (event) => {
    setOptChanged(event.target.checked);
  };

  const updateUser = () => {
    let obj = {
      _id: id,
      firstName: firstName,
      secondName: secondName,
      city: city,
      telef: telef,
      password: password,
      opt: optChanged,
      manager: phoneNumber
    };
    putOneUser(obj).then(data => console.log(data));
  };

  return (
    <tbody>
      <tr>
        <td>{firstName}</td>
        <td>{secondName}</td>
        <td>{city}</td>
        <td>{telef}</td>
        <td>{password}</td>
        <td>
          <div id="toggles">
            <input
              type="checkbox"
              name={`checkbox-${id}`}
              id={`checkbox-${id}`}
              className="ios-toggle"
              checked={optChanged}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor={`checkbox-${id}`}
              className="checkbox-label"
              data-off="off"
              data-on="on"
            ></label>
          </div>
        </td>
        <td>
          {optChanged ? (
            <div>
              <select
                name="user_manager"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="">Оберіть менеджера</option>
                <optgroup label="Львів">
                  <option value="1">Марина</option>
                </optgroup>
                <optgroup label="Закарпаття">
                  <option value="2">Микола</option>
                </optgroup>
                <optgroup label="Вінниця">
                  <option value="3">Роман</option>
                </optgroup>
              </select>
              {phoneNumber && (
                <p>
                  Номер телефону: {phoneNumber.name}, {phoneNumber.tel},{manager ? manager.tel : null}
                </p>
              )}
            </div>
          ) : null}
        </td>
        <td>
          <button
            className="Table__button button-edit"
            onClick={updateUser}
          >
            Оновити
          </button>
        </td>
      </tr>
    </tbody>
  );
}

export default ListItem;
