

const ButtonBack = () => {

    const handleGoBack = () => {
        // Логіка для повернення назад
        window.history.back();
    }

    return (
        <button
            onClick={handleGoBack}
            style={{
                border: '1px solid rgb(6, 152, 255)',
                padding: '5px',
                marginLeft: '5px'
            }}
        >{`< Назад`}</button>
    )
}

export default ButtonBack